.wrapper {
    display: flex;
    width: 100%;
    justify-content: center;

    &.adaptive .root {
        max-width: 100%;

        &.hasIcon {
            padding: 5px 24px 5px 76px;
        }
    }

    &.full .root {
        width: 100%;
    }
}

.root {
    display: flex;
    align-items: center;
    padding: 5px 24px;
    min-height: 56px;
    color: #2979ff;
    background-color: #f7f7fa;
    user-select: none;
    overflow: hidden;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.02px;
    position: relative;

    @media all and (min-width: 1025px) {
        &:hover {
            box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.24);
            transform: translateY(-2px);
        }
    }
}

.hasIcon {
    padding: 5px 76px;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 100%;

    > svg {
        display: block;
        flex-shrink: 0;
    }
}

.content {
    flex: 1;
    text-align: center;
    max-width: 100%;
}

.subTitle {
    font-weight: normal;
    font-size: 11px;
    line-height: 1;
    opacity: 0.5;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.active .root {
    font-weight: 700;
    text-decoration: underline;
}
