@import 'vars';

.rootContainer {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    position: fixed;
    bottom: 0;
    left: 0;
    user-select: none;
}

.root {
    width: 600px;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    z-index: 1000000;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #000;
    user-select: none;
    display: flex;
    flex-direction: column;

    &.compact {
        height: unset;
        max-height: calc(100% - 20px);
        border-radius: 12px 12px 0 0;

        &.desktop {
            border-radius: 12px;
            max-height: calc(100% - 32px);
            margin: auto;
            position: relative;
            left: 0;
        }
    }

    &.desktop {
        left: calc(50% - 300px);
    }
}

.overlay {
    z-index: 1000;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.header {
    width: 100%;
    height: 50px;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    padding-right: 56px;

    .title {
        flex: 1;
        height: 100%;
        display: flex;
        margin: 0 6px;
        align-items: center;
        justify-content: center;
    }

    .closeButton {
        width: 50px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: transform 0.3s ease;

        &:hover {
            transform: rotate(-90deg);
        }
    }
}

.content {
    z-index: 1001;
    flex: 1;
    overflow: hidden auto;

    &.padded {
        padding: 16px;
    }

    &::-webkit-scrollbar {
        width: 0;
    }

    &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0);
    }
}
