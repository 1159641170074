@import 'vars';

$btn-height: 44px;

.root {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 44px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    background-color: transparent;
    flex-shrink: 0;

    &:focus {
        outline: 0;
    }

    &:disabled,
    &.disabled {
        pointer-events: none;
        background-color: #333;
        border-color: transparent;
        opacity: 0.2;
    }
}

.container {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    max-width: 100%;

    svg {
        position: relative;
        top: -1px;
        vertical-align: middle;
        margin: 0 4px 0 -8px;
    }
}

.standart {
    padding: 0 25px;
}

.large {
    height: 45px;
    padding: 0 25px;
}

.small {
    height: 32px;
    padding: 0 15px;
}

.inline {
    height: unset;
    width: unset;
    padding: 0;
}

.full-width {
    width: 100%;
}

.rounded {
    border-radius: 100px;
}

.transparent {
    color: #2979ff;
    background-color: transparent;

    &:disabled,
    &.disabled {
        background-color: transparent;
    }
}

.primary {
    $bg-color: #007aff;
    color: #fff;
    background-color: $bg-color;

    &Outline {
        $color: $bg-color;
        $border-color: $bg-color;
        background-color: transparent;
        color: $color;
        border-color: $border-color;

        &:hover {
            color: lighten($color, 10%);
            border-color: lighten($border-color, 10%);
        }
    }
}

.primary2 {
    $bg-color: #ff3265;
    color: #fff;
    background-color: $bg-color;
}

.secondary {
    $border-color: #d1d1d6;
    $bg-color: #f9f9f9;
    color: #29323c;
    background-color: $bg-color;
    border-color: $border-color;

    &:hover {
        background-color: #f5f5f5;
    }
}

.secondary2 {
    $bg-color: #f0f0f0;
    color: #333;
    background-color: $bg-color;

    &:hover {
        background-color: darken($bg-color, 10%);
    }
}

.warning {
    color: #8a8885;
    background-color: #e9e2d2;

    &:hover {
        color: #fef7e4;
        background-color: #2b303a;
    }

    &:active {
        transition: none;
        color: rgba(255, 255, 255, 0.6);
    }
}

.danger {
    $bg-color: #dc5960;
    $color: #fff;
    color: $color;
    background-color: $bg-color;

    &:hover {
        color: #fff;
        background-color: darken($bg-color, 20%);
    }

    &:active {
        transition: none;
        color: rgba(255, 255, 255, 0.6);
    }
}

.success {
    $bg-color: #3eb991;
    color: #ffffff;
    background-color: $bg-color;

    &:hover {
        background-color: darken($bg-color, 10%);
    }

    &:active {
        transition: none;
        color: rgba(255, 255, 255, 0.6);
    }
}

.grey {
    $bg-color: #c7c7cc;
    color: #ffffff;
    background-color: $bg-color;

    &:hover {
        background-color: darken($bg-color, 10%);
    }

    &:active {
        transition: none;
        color: rgba(255, 255, 255, 0.6);
    }
}

.white {
    $bg-color: #fff;
    color: #000;
    background-color: $bg-color;

    &:hover {
        background-color: darken($bg-color, 2%);
    }

    &:active {
        transition: none;
        color: rgba(0, 0, 0, 0.6);
    }
}

.link {
    color: $link-color;

    &:active {
        outline: 0;
        box-shadow: none;
        color: $link-active-color;
    }
}

.solid {
    $bg-color: #333;
    background-color: $bg-color;
    font-weight: bold;
    font-size: 16px;
    color: #fff;

    &.disabled,
    &[disabled] {
        background-color: rgba(0, 0, 0, 0.1);
        color: #fff;
    }
}

.secondaryOutline {
    $color: #2b303a;
    $border-color: #2b303a;

    background-color: transparent;
    color: $color;
    border-color: $border-color;

    &:hover {
        color: lighten($color, 30%);
        border-color: lighten($border-color, 30%);
    }
}

.nopadding {
    padding: 0;
}

.icon {
    margin: 0 0.5em;

    &:last-child {
        margin-right: 0;
    }

    &:first-child {
        margin-left: 0;
    }
}

.noborder {
    border-color: transparent;
}

.socialBtnOnly {
    padding: 0;
    min-width: 44px;

    svg {
        margin: 0;
        top: 0;
    }
}

.socialBtnWithText {
    padding: 0 25px 0 6px;

    svg {
        margin: 0 12px 0 0;
        top: 0;
    }
}

.facebook {
    $bg-color: #4267b2;
    color: #fff;
    background-color: $bg-color;

    &:hover {
        background-color: darken($bg-color, 10%);
    }

    &:active {
        transition: none;
        color: rgba(255, 255, 255, 0.6);
    }
}

.iconFixedLeft,
.iconFixedRight {
    padding: 0 50px;

    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
    }
}

.iconFixedLeft .icon {
    left: 8px;
}

.iconFixedRight .icon {
    right: 8px;
}
